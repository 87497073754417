@tailwind base;
@tailwind utilities;
@tailwind components;

$pub: "../public/";

.without-ring {
    @apply focus:ring-0 focus:ring-offset-0;
}

.branding {
	width: 100%;
	height: 100%;

	mask: url("#{$pub}/assets/icons/branding.svg") no-repeat center;
	background: linear-gradient(to right top, #ee61e7, #a931ee);
	background-size: 200% 200%;

	-webkit-animation: brand-anim 10s ease infinite;
	-moz-animation: brand-anim 10s ease infinite;
	animation: brand-anim 10s ease infinite;
}
.branding-long {
	width: 100%;
	height: 100%;

	mask: url("#{$pub}/assets/icons/branding_long.svg") no-repeat center;
	background: linear-gradient(to right top, #ee61e7, #a931ee);
	background-size: 200% 200%;

	-webkit-animation: brand-anim 10s ease infinite;
	-moz-animation: brand-anim 10s ease infinite;
	animation: brand-anim 10s ease infinite;
}

@-webkit-keyframes brand-anim {
	0% { background-position: 15% 0%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 15% 0%; }
}
@-moz-keyframes brand-anim {
	0% { background-position: 15% 0%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 15% 0%; }
}
@keyframes brand-anim {
	0% { background-position: 15% 0%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 15% 0%; }
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
/*
@font-face {
	font-family: 'Segoe UI Variable Small';
	src: local('Segoe UI Variable Small'),
	url('../public/assets/fonts/SegoeUI.ttf') format('truetype-variations');
	font-variation-settings: 'opsz' 1;
	font-weight: 300 700;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI Variable Text';
	src: local('Segoe UI Variable Text'),
	url('../public/assets/fonts/SegoeUI.ttf') format('truetype-variations');
	font-variation-settings: 'opsz' 10.5;
	font-weight: 300 700;
	font-style: normal;
}

@font-face {
	font-family: 'Segoe UI Variable Display';
	src: local('Segoe UI Variable Display'),
	url('../public/assets/fonts/SegoeUI.ttf') format('truetype-variations');
	font-variation-settings: 'opsz' 36;
	font-weight: 300 700;
	font-style: normal;
}
*/